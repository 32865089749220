<template>
  <div class="container normal forest-grass-box">
    <operationBar/>
    <CusiumMap class="forest-grass-lastItem"/>
  </div>
</template>

<script>
import CusiumMap from "@/components/cesium/index";
import operationBar from "./operationBar/";

export default {
  name: "FG-Inspection",
  components: {
    CusiumMap,
    operationBar
  },
  data() {
    return {}
  }
};
</script>

<style lang="scss">
.forest-grass-box{
  width: 100%;
  height: 100%;
  display: flex;
  .forest-grass-lastItem{
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
