var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container normal forest-grass-box" },
    [
      _c("operationBar"),
      _c("CusiumMap", { staticClass: "forest-grass-lastItem" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }