var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-manage" }, [
    _c(
      "div",
      { staticClass: "project-status" },
      [
        _c(
          "el-select",
          {
            staticClass: "uav-select",
            attrs: { placeholder: "全部状态" },
            on: {
              change: function ($event) {
                return _vm.search()
              },
            },
            model: {
              value: _vm.projectStatus,
              callback: function ($$v) {
                _vm.projectStatus = $$v
              },
              expression: "projectStatus",
            },
          },
          _vm._l(_vm.options, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
        _c(
          "el-input",
          {
            staticClass: "project-search-input uav-input",
            attrs: { onclick: "search", placeholder: "请输入内容" },
            model: {
              value: _vm.projectName,
              callback: function ($$v) {
                _vm.projectName = $$v
              },
              expression: "projectName",
            },
          },
          [
            _c("template", { slot: "suffix" }, [
              _c("div", { staticClass: "search-icon-container" }, [
                _c("i", {
                  staticClass: "el-icon-search",
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                }),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "project-list" },
      _vm._l(_vm.projectData, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            class:
              item.id === _vm.selectProject
                ? "project-list-item project--choosen"
                : "project-list-item",
            on: {
              click: function ($event) {
                return _vm.chooseProject(item)
              },
            },
          },
          [
            _c("p", { staticClass: "item-top" }, [
              _c(
                "span",
                { staticClass: "item-top-left" },
                [
                  _c("span", { staticClass: "item-top-title" }, [
                    _vm._v(_vm._s(item.projectName)),
                  ]),
                  _c("StatusLabel", { attrs: { labelStatus: item.status } }),
                ],
                1
              ),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  (_vm.permission.FG_edit || _vm.permission.FG_del) &&
                  _vm.hasProjectView
                    ? _c(
                        "el-dropdown",
                        { on: { command: _vm.changeItem } },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("img", {
                              staticClass: "icon icon-more",
                              attrs: {
                                src: "/img/project/icon-more.png",
                                alt: "",
                              },
                            }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "black-style",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            [
                              item.projectStatus !== "2" &&
                              _vm.permission.FG_edit &&
                              _vm.showProjectDetail
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: _vm.beforeHandleCommand(
                                          "edit",
                                          item
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                编辑\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.permission.FG_del && _vm.showProjectDetail
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: _vm.beforeHandleCommand(
                                          "delete",
                                          item
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                删除\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("p", { staticClass: "item-bottom" }, [
              _c("span", { staticClass: "item-title" }, [
                _vm._v("项目团队：" + _vm._s(item.deptNames)),
              ]),
            ]),
            _c("p", { staticClass: "item-bottom" }, [
              _c("span", { staticClass: "item-title" }, [
                _vm._v("负责人：" + _vm._s(item.projectLeaderName)),
              ]),
            ]),
            _c("p", { staticClass: "item-bottom" }, [
              _c("span", { staticClass: "item-bottom-time" }, [
                _vm._v("开始时间：" + _vm._s(item.startTime)),
              ]),
            ]),
            _c("p", { staticClass: "item-bottom" }, [
              _c("span", { staticClass: "item-bottom-time" }, [
                _vm._v("结束时间：" + _vm._s(item.endTime)),
              ]),
            ]),
            _c(
              "p",
              {
                staticClass: "item-right",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toDetail(item)
                  },
                },
              },
              [
                _vm.showProjectDetail
                  ? _c("span", { staticClass: "item-bottom-project" }, [
                      _vm._v("\n          进入项目>>\n        "),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "project-add" },
      [
        _vm.permission.FG_add && _vm.hasProjectView
          ? _c(
              "el-button",
              {
                staticClass: "project-add-btn",
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.addProject },
              },
              [_vm._v("\n      新增项目\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }